<template>
    <div class="profile-dropdown">
        <div @click="toggleProfileMenu" class="profile-button" :class="{ 'open': profileMenuOpen }">
            <div class="d-flex align-items-center">
                <div class="menu-icon">
                    <component v-if="!profile || !profile.profile_photo" :is="iconProfileComponent" class="svg-icon" />
                    <img v-else :src="profile.profile_photo" class="profile-pic">
                </div>
                <div class="menu-text">
                    <span v-if="profile"> {{ profile.first_name }}</span>
                    <span v-else>Mi cuenta</span>
                </div>
            </div>
            <font-awesome-icon :icon="['fas', 'chevron-down']" :class="{ 'arrow-rotated': profileMenuOpen }" class="arrow-icon" />
        </div>
        <div v-if="profileMenuOpen" class="profile-menu">

            <router-link to="/profile">
                <div class="profile-item">
                    <font-awesome-icon :icon="['far', 'user']" class="icon" />
                    <span>Mi perfil</span>
                </div>
            </router-link>
            <a @click="openSettingsModal" to="/settings">
                <div class="profile-item">
                    <font-awesome-icon :icon="['far', 'gear']" class="icon" />
                    <span>Ajustes</span>

                </div>
            </a>
            <hr>
            <a @click="handleLogout" tabindex="-1">
                <div class="profile-item">
                    <font-awesome-icon :icon="['fas', 'arrow-right-from-bracket']" class="icon" />
                    <span class="signout-text">Cerrar sesión</span>
                </div>
            </a>
        </div>
    </div>
</template>

<script>

import UserService from '@/services/user.service.js';
import ProfileIcon from "@/components/Icons/ProfileIcon.vue"


export default {
    name: 'ProfileDropdown',
    components: {
        ProfileIcon
    },

    data() {
        return {
            profileMenuOpen: false,
            profile: {},
            isLoading: true
        };
    },

    computed: {
        iconProfileComponent() {
            return ProfileIcon;
        }
    },
    methods: {

        async start() {
            await this.get_profile()
        },

        async get_profile() {
            try {
                const response = await UserService.get_current_user();
                this.profile = response.data.profile;
                // console.log(this.profile)
                this.isLoading = false;
            } catch (error) {
                console.error('Error getting profile:', error);
            }

        },

        toggleProfileMenu() {
            this.profileMenuOpen = !this.profileMenuOpen;
            if (this.profileMenuOpen) {
                this.$router.push('/profile');
            }
        },
        openSettingsModal() {
            // Inicializa y muestra el modal usando Bootstrap
            const modalElement = document.getElementById('settingsModal');
            const modal = new this.$bootstrap.Modal(modalElement);
            // console.log(modal, "modal")
            modal.show();
        },
        async handleLogout() {
            await this.$store.dispatch("auth/logout");
            this.$router.push("/login");
        }
    },
    created() {
        this.start()
    },
};
</script>

<style scoped>
.profile-button {
    border-radius: 10px;
    background: var(--nubeqa-dark-blue);
    box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.20);
    padding: 10px 15px 10px 15px;
    color: white;
    align-items: center;
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    margin-bottom: 10px;
    cursor: pointer;
}

.profile-button.open {
    background: #14329B;
    box-shadow: 0px 0px 24px 0px rgba(98, 181, 255, 0.90), 1px 1px 10px 0px rgba(0, 0, 0, 0.20);
}

.menu-icon {
    width: 35px;
    height: 35px;
    border-radius: 100px;
    background-color: var(--nubeqa-light-blue);
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu-text {
    padding-left: 20px;
}

.profile-pic {
    width: 25px;
    object-fit: cover;
}

.arrow-icon {
    margin-left: 10px;
    transition: transform 0.3s ease;
}

.arrow-rotated {
    transform: rotate(180deg);
}

hr {
    border-color: white;
    border-color: white;
    margin: 10px 0;
}

.profile-menu {
    position: relative;
}

.profile-menu a {
    text-decoration: none;
    color: white;
    margin-bottom: 20px;
    cursor: pointer;
}

.profile-menu a:last-child {
    margin-bottom: 0;
}


.profile-item {
    padding: 10px;
    color: white;
}

.profile-item:hover {
    color: #A1CBEF;
}

.profile-item span {
    padding-left: 30px;
}

.icon {
    position: absolute;
    left: 10px
}

.router-link-active .profile-item {
    color: #599ED8;
}
</style>

<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
        <path d="M13.5 22.5V11.25" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M20.25 22.5V4.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M6.75 22.5V18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
</template>

<script>
export default {
    name: 'RankingIcon'
};
</script>

import apiService from "@/services/api.service";

class UserService {
	async get_current_user() {
		try {
			const response = await apiService.get("/user/me/");
			return { status: response.status, data: response.data };
		} catch (error) {
			return {
				status: error.response ? error.response.status : 500,
				data: error.response ? error.response.data : { message: "Server error" },
			};
		}
	}

	async update_user(userUUID, formData) {
		try {
			const response = await apiService.put(`/user/${userUUID}/`, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});
			return { status: response.status, data: response.data };
		} catch (error) {
			return {
				status: error.response ? error.response.status : 500,
				data: error.response ? error.response.data : { message: "Server error" },
			};
		}
	}

	async update_user_password(userUUID, data) {
		try {
			const response = await apiService.put(`/user/${userUUID}/password/`, data);
			return { status: response.status, data: response.data };
		} catch (error) {
			return {
				status: error.response ? error.response.status : 500,
				data: error.response ? error.response.data : { message: "Server error" },
			};
		}
	}

	async manage_user_list(videoUUID) {
		try {
			const response = await apiService.put(`/user/me/list/${videoUUID}/`);
			return { status: response.status, data: response.data };
		} catch (error) {
			return {
				status: error.response ? error.response.status : 500,
				data: error.response ? error.response.data : { message: "Server error" },
			};
		}
	}

	async manage_viewed_videos() {
		try {
			const response = await apiService.get("/user/me/started");
			return { status: response.status, data: response.data };
		} catch (error) {
			return {
				status: error.response ? error.response.status : 500,
				data: error.response ? error.response.data : { message: "Server error" },
			};
		}
	}

	async manage_finished_videos() {
		try {
			const response = await apiService.get("/user/me/finished");
			return { status: response.status, data: response.data };
		} catch (error) {
			return {
				status: error.response ? error.response.status : 500,
				data: error.response ? error.response.data : { message: "Server error" },
			};
		}
	}

	async get_my_list() {
		try {
			const response = await apiService.get("/user/me/list/");
			return { status: response.status, data: response.data };
		} catch (error) {
			return {
				status: error.response ? error.response.status : 500,
				data: error.response ? error.response.data : { message: "Server error" },
			};
		}
	}

	async get_users_list() {
		try {
			const response = await apiService.get("/user/list");
			return { status: response.status, data: response.data };
		} catch (error) {
			return {
				status: error.response ? error.response.status : 500,
				data: error.response ? error.response.data : { message: "Server error" },
			};
		}
	}

	async user_validation_token(params) {
		try {
			const response = await apiService.get("user/invitation/validate", {
				params: params,
				headers: {
					Authorization: "",
				},
			});
			return { status: response.status, data: response.data };
		} catch (error) {
			return {
				status: error.response ? error.response.status : 500,
				data: error.response ? error.response.data : { message: "Server error" },
			};
		}
	}

	async user_register(data) {
		try {
			const response = await apiService.post("user/register/", data, {
				headers: {
					Authorization: "",
				},
			});
			return { status: response.status, data: response.data };
		} catch (error) {
			return {
				status: error.response ? error.response.status : 500,
				data: error.response ? error.response.data : { message: "Server error" },
			};
		}
	}

	async user_verify_email(data) {
		try {
			const response = await apiService.post("user/verify/", data, {
				headers: {
					Authorization: "",
				},
			});
			return { status: response.status, data: response.data };
		} catch (error) {
			return {
				status: error.response ? error.response.status : 500,
				data: error.response ? error.response.data : { message: "Server error" },
			};
		}
	}

	async verify_reset_password_token(data) {
		try {
			const response = await apiService.post("auth/password_reset/verify/reset_password_token/", data, {
				headers: {
					Authorization: "",
				},
			});
			return { status: response.status, data: response.data };
		} catch (error) {
			return {
				status: error.response ? error.response.status : 500,
				data: error.response ? error.response.data : { message: "Server error" },
			};
		}
	}
}

export default new UserService();

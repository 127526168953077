<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
        <path
        style="fill:#00000000;" d="M21.375 4.5H5.625C4.38236 4.5 3.375 5.50736 3.375 6.75V22.5C3.375 23.7426 4.38236 24.75 5.625 24.75H21.375C22.6176 24.75 23.625 23.7426 23.625 22.5V6.75C23.625 5.50736 22.6176 4.5 21.375 4.5Z"
            stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path style="fill:#00000000;" d="M18 2.25V6.75" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path style="fill:#00000000;" d="M9 2.25V6.75" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path style="fill:#00000000;" d="M3.375 11.25H23.625" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
</template>

<script>
export default {
    name: 'CalendarIcon'
};
</script>

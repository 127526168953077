<template>
    <div id="navbar">
        <div>
            <button class="btn btn-primary d-none"><font-awesome-icon :icon="['far', 'bars']" /></button>
        </div>
        <div>
            <div class="nav-logo">
                <router-link to="/home">
                    <img src="assets/images/logos/logo-sirius-opacity-0.5.svg" />
                </router-link>

            </div>
        </div>
        <div></div>
    </div>
</template>
<script>
export default {
    name: "NavbarMenu",
    data() {
        return {
            loading: true,
        };
    },
    computed: {
    },

    methods: {


    },
    created() {
    },
};
</script>

<style scoped>
#navbar {
    display: none;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.nav-logo img {
    width: 60px;
}
</style>
<template>
    <li class="menu-item">
        <router-link :to="to" class="menu-link" :class="icon" @click="handleClick($event, to)">
            <div class="menu-icon">
                <component :is="iconComponent" class="svg-icon" />
            </div>
            <div class="menu-text">{{ text }}</div>
        </router-link>
    </li>
</template>

<script>
import HomeIcon from '@/components/Icons/HomeIcon.vue';
import Season1Icon from '@/components/Icons/Season1Icon.vue';
import Season2Icon from '@/components/Icons/Season2Icon.vue';
import RankingIcon from '@/components/Icons/RankingIcon.vue';
import CalendarIcon from '@/components/Icons/CalendarIcon.vue';

export default {
    name: 'SidebarMenuItem',
    props: {
        to: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            required: true,
            validator(value) {
                return ['HomeIcon', 'RankingIcon', 'Season1Icon', 'Season2Icon', 'CalendarIcon'].includes(value);
            }
        },
        text: {
            type: String,
            required: true
        }
    },
    computed: {
        iconComponent() {
            switch (this.icon) {
                case 'HomeIcon':
                    return HomeIcon;
                case 'Season1Icon':
                    return Season1Icon;
                case 'Season2Icon':
                    return Season2Icon;
                case 'RankingIcon':
                    return RankingIcon;
                case 'CalendarIcon':
                    return CalendarIcon;
                default:
                    return null;
            }
        }
    },
    methods: {
        handleClick(event, to) {

            // WARNING: This is very scuffed, change in the future
            if (to == "/season") {
                sessionStorage.setItem('season', "670abb8c-7eee-455f-b86c-6765764fcc76")
            }
            event.currentTarget.blur(); // Remove focus from the clicked element
            this.$emit('click');
        }
    }
};
</script>

<style>
.menu-item {
    list-style: none;
}

.menu-link {
    text-decoration: none;
    display: flex;
    align-items: center;
    color: #fff;
    cursor: pointer;
    width: 100%;
    border-radius: 50px;
    padding: 7px 10px;
    margin-bottom: 20px;
}



.menu-link .menu-icon {
    position: absolute;
    display: flex;
    align-items: center;
    width: 30px;
}

.menu-link .menu-text {
    padding-left: 40px;
    font-family: 'Gotham';
    font-weight: 700;
    font-style: normal;
}

.menu-link:hover {
    color: var(--nubeqa-light-blue);
}

.menu-link:focus,
.menu-link:hover:focus {
    color: var(--nubeqa-light-blue);
}

.menu-link:focus .menu-text,
.menu-link:focus .menu-icon path,
.menu-link:hover .menu-icon path {
    color: var(--nubeqa-light-blue);
    fill: var(--nubeqa-light-blue);
    stroke: var(--nubeqa-light-blue);
}

.menu-link:focus.router-link-active {
    background-color: transparent;
    box-shadow: none;
}

.menu-link.router-link-active .menu-text,
.menu-link.router-link-active .menu-icon path,
.menu-link:focus.router-link-active .menu-text,
.menu-link:focus.router-link-active .menu-icon path {
    color: var(--nubeqa-blue);
    fill: var(--nubeqa-blue);
    stroke: var(--nubeqa-blue);
    font-weight: 700;
    font-style: normal;
}
</style>

<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
        <path
            style="fill:#00000000;"
            d="M3.375 10.125L13.5 2.25L23.625 10.125V22.5C23.625 23.0967 23.3879 23.669 22.966 24.091C22.544 24.5129 21.9717 24.75 21.375 24.75H5.625C5.02826 24.75 4.45597 24.5129 4.03401 24.091C3.61205 23.669 3.375 23.0967 3.375 22.5V10.125Z"
            stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path style="fill:#00000000;" d="M10.125 24.75V13.5H16.875V24.75" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

</template>

<script>
export default {
    name: 'HomeIcon'
};
</script>

<template>
    <svg
   version="1.1"
   width="27"
   height="27"
   viewBox="0 0 347 347"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:svg="http://www.w3.org/2000/svg">
      <path
       d="M 0.87055434,258.96498 C 1.02761,257.99716 10.610352,245.55178 48.545406,197.04831 l 47.478289,-60.70537 -29.695811,-0.0851 -29.695807,-0.0851 v -0.81588 c 0,-0.57121 5.451045,-7.79268 18.177677,-24.08154 9.997723,-12.796119 18.360223,-23.354849 18.583334,-23.463859 0.223111,-0.109 0.741121,-0.3715 1.151133,-0.58333 0.940548,-0.48592 182.236929,-0.56269 181.936779,-0.077 -0.10473,0.16946 0.0466,0.35696 0.33631,0.41667 0.36456,0.0751 -5.29266,7.54887 -18.37505,24.275219 l -18.90178,24.16667 -30.5286,0.16667 -30.5286,0.16667 -48.2285,61.66412 c -32.652251,41.74862 -48.480545,61.73496 -49.008928,61.88344 -0.429243,0.12063 -14.233511,0.23427 -30.676152,0.25255 l -29.89571136,0.0332 z M 136.66131,259.86806 c -0.3662,-0.23181 0.85556,-1.99519 5.5,-7.9382 3.28392,-4.2021 11.37077,-14.55986 17.97077,-23.01722 l 12,-15.37703 20.19656,-0.013 20.19657,-0.013 30.30343,-38.73177 c 16.66689,-21.30248 30.35344,-38.85248 30.41455,-39 0.0611,-0.14753 -8.16089,-0.26823 -18.27112,-0.26823 h -18.38223 l 0.10445,-0.94155 c 0.0721,-0.64974 5.68317,-8.08383 18.10446,-23.98649 14.18613,-18.162139 18.15891,-23.047799 18.75,-23.058449 0.4125,-0.007 0.75,-0.17028 0.75,-0.36188 0,-0.43696 51.06777,-0.18929 55.5,0.26916 5.01783,0.51902 9.05608,2.55031 12.5,6.28765 2.54653,2.76351 3.512,4.92126 3.85912,8.624889 0.53169,5.67289 0.17819,13.2627 -0.76072,16.33334 -0.95049,3.1085 -3.73109,8.42353 -6.7418,12.88673 -1.64973,2.44563 -16.62218,21.8466 -33.27212,43.11327 l -30.2726,38.66667 16.78257,0.0864 16.78257,0.0864 -0.10518,0.82738 c -0.0616,0.48439 -7.33569,10.07174 -17.5469,23.12695 -14.95371,19.11858 -17.58674,22.32856 -18.45833,22.50288 -1.64896,0.32979 -135.38042,0.22658 -135.90405,-0.10489 z"
       id="pathT1" fill="white" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


</template>

<script>
export default {
    name: 'Season1Icon'
};
</script>
